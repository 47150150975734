import Glide, { Controls, Swipe, Autoplay } from "@glidejs/glide/dist/glide.modular.esm"
import { q } from "../helper/q"
import { EventObserver } from "../helper/eventObserver"
import { number1 } from "./slide/number1"
import { year } from "./slide/year"
import { markets } from "./slide/markets"
import { china } from "./slide/china"
import { forest } from "./slide/forest"

export const mainSliderObserver = new EventObserver()

export const sliderInit = () => {
  if (q(".glide")) {
    number1()
    year()
    markets()
    china()
    forest()

    const slider = new Glide(".glide", {
      type: "slider",
      startAt: 0,
      perView: 1,
      gap: 0,
      bound: true,
      autoplay: 5000
    })

    slider.on("mount.after", slide => {
      mainSliderObserver.broadcast({
        active: slider._c.Html.slides[0]
      })
    })
    slider.on(["run.after"], () => {
      // console.log(slider._c.Html.slides[slider.index])
      mainSliderObserver.broadcast({
        active: slider._c.Html.slides[slider.index]
      })
    })
    slider.mount({
      Controls,
      Swipe,
      Autoplay
    })
  }
}
