import { mainSliderObserver } from "../slider"
import Vivus from "vivus"
import pose from "popmotion-pose"

let slide = {}

const textProps = {
  initialPose: "stop",
  start: {
    delay: 300,
    delayChildren: 200,
    staggerChildren: 150
  },
  stop: {
    delay: 100,
    staggerChildren: 120
  }
}

const itemProps = {
  initialPose: "stop",
  start: {
    opacity: 1,
    y: 0,
    transition: 1200
  },
  stop: {
    opacity: 0,
    y: 60,
    transition: 1200
  }
}

export const forest = () => {
  if (document.querySelector(".forestSlide")) {
    const svgInit = new Vivus("forest-path", {
      type: "delayed",
      duration: 250,
      delay: 50,
      start: "manual",
      onReady: vivusInstance => {
        vivusInstance.el.style.visibility = "visible"
      }
    })

    const text = document.querySelector(".forestSlideContent")
    const items = [text.querySelector(".forestSlideContent p"), text.querySelector(".forestSlideButtons")]

    const textPoser = pose(text, textProps)
    items.forEach(item => textPoser.addChild(item, itemProps))

    mainSliderObserver.subscribe(slides => {
      slide = slides.active
      if (slide.classList.contains("forestSlide")) {
        svgInit.el.style.visibility = "visible"
        svgInit.reset().play()
        textPoser.set("start")
      } else {
        svgInit.reset()
        svgInit.el.style.visibility = "hidden"
        textPoser.set("stop")
      }
    })
  }
}
